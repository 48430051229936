var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "wrap content-f flex f-d-c f-ai-c f-jc-c" }, [
      _c("span", { staticClass: "title" }, [_vm._v("支付金额")]),
      _c("div", { staticClass: "money" }, [
        _c("i", [_vm._v("￥")]),
        _c("span", [_vm._v(_vm._s(_vm.totalFee.toString().split(".")[0]))]),
        _c("i", [
          _vm._v(
            _vm._s(
              _vm.totalFee.toString().split(".")[1]
                ? "." + _vm.totalFee.toString().split(".")[1]
                : ""
            )
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "wrap content-s flex f-ai-c f-d-c" },
      [
        _c(
          "van-radio-group",
          {
            staticStyle: { width: "100%" },
            model: {
              value: _vm.payType,
              callback: function ($$v) {
                _vm.payType = $$v
              },
              expression: "payType",
            },
          },
          [
            _c(
              "van-cell-group",
              [
                _c("van-cell", {
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { title: "微信支付" },
                  on: {
                    click: function ($event) {
                      _vm.radio = "1"
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("img", {
                            staticClass: "pay-icon",
                            attrs: {
                              src: require("../assets/images/wechat.png"),
                              alt: "",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "right-icon",
                      fn: function () {
                        return [_c("van-radio", { attrs: { name: "1" } })]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c("van-cell", {
                  staticStyle: { display: "flex", "align-items": "center" },
                  attrs: { title: "找人代付" },
                  on: {
                    click: function ($event) {
                      _vm.radio = "1"
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("img", {
                            staticClass: "pay-icon",
                            attrs: {
                              src: require("../assets/images/df.png"),
                              alt: "",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "right-icon",
                      fn: function () {
                        return [_c("van-radio", { attrs: { name: "2" } })]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "btn-content flex f-ai-c f-jc-c" }, [
      _vm.payType === "1"
        ? _c("div", { staticClass: "active", on: { click: _vm.gotoPay } }, [
            _c("span", [_vm._v(_vm._s(_vm.btnText))]),
          ])
        : _c("span", { staticClass: "df-tips" }, [
            _vm._v(" 点击右上角【···】选择【发送给好友】 "),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }